import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="container max">
      <div className="row py-5 align-items-center"
        style={{
          minHeight: 'calc(-228px + 100vh)'
        }}>
        <div className="col">
          <h3>NOT FOUND</h3>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
